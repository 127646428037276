.Profoessional-Section {
  background-color: #000000;
  padding: 10px 80px;
}

.Profesh-Header {
  color: white;
}

.Card-Container {
  background-color: green;
  padding: 20px;
  /* width: 50vw; */
  flex: 1 0 33%; 
  margin: 20px 20px 20px 0;
  border-radius: 7px;
  /* max-width: 40vw; */
  color: white;
  text-decoration: none;
  
}

.Cards-Container {
  flex-wrap: wrap;
  display: flex;
}

.Card-Container:hover {
  box-shadow: 7px 7px 10px gray;
  transform: translateX(-10px) translateY(-10px);
}

.Profesh-Logo {
  height: 2rem;
}

@media only screen and (max-width: 800px) {
  .Profoessional-Section {
    align-items: center;
    padding: 10px 0;
    text-align: center;
  }

  .Card-Container:hover {
    box-shadow: none;
    transform: none;
  }

  .Card-Container {
    flex: 1;
    margin: 5px 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Cards-Container {
    background-color: white;
  }
  
  .Profesh-Logo {
    object-fit: contain;
    height: 3rem;
    max-width: 100%;
  }
}