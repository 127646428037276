.Mobile-Menu-Container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
}

.Mobile-Menu-Top {
  /* flex: 1; */
  background-color: black;
  color: white;
  min-height: 40vh;
}

.Mobile-Menu-Bottom {
  /* flex: 1; */
  background-color: transparent;
}

.Nav-Link-Mobile {
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 2; 
  text-decoration: none;
  cursor: pointer;
  color: unset;
}

.Menu-Close {
  flex: 1;
  align-self: flex-end;
  padding-right: 2rem;
  font-size: 24px;
  padding-top: 0.5rem;
}

.Nav-Logo {
  flex: 1;
  max-height: 2.5rem;
  padding: 0 0 10px 0;
}