@font-face {
  font-family: 'Roboto';
  src: url('./assets/Roboto/Roboto-Black.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/Roboto/Roboto-Light.ttf');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Flex-Row {
  display: flex;
  flex-direction: row;
}

.Flex-Column {
  display: flex;
  flex-direction: column;
}

.J-S-B {
  justify-content: space-between;
}

.J-C {
  justify-content: center;
}

.J-S-A {
  justify-content: space-around;
}

.J-F-S {
  justify-content: flex-start;
}

.J-F-E {
  justify-content: flex-end;
}

.A-S-B {
  align-items: space-between;
}

.A-F-E {
  align-items: flex-end;
}

.A-F-S {
  align-items: flex-start;
}

.A-C {
  align-items: center;
}

.A-S-F-E {
  align-self: flex-end;
}

.A-S-F-S {
  align-self: flex-start;
}

.A-S-C {
  align-self: center;
}

.Cursor-Pointer {
  cursor: pointer;
}

.Full-Width {
  width: 100%;
}

.Bold {
  font-weight: bold;
}

.Light-Text {
  font-family: 'Roboto-Light';
}

.Bold-Text {
  font-family: 'Roboto';
}

button {
  cursor: pointer;
}