.Banner {
  background-image: url("./boat.jpeg");
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 80vh;
  background-repeat: no-repeat;
  color: rgb(27, 100, 209);
  font-weight: 700;
  padding: 45px 0;
  max-width: 100%;
}

.Links {
  width: 40%;
}

.Banner-Button {
  /* max-width: 100px; */
  /* margin-top: 100px; */
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: rgb(19, 109, 240);
  color: white;
  width: 200px;
  padding: 10px;
  font-size: 16px;
  box-shadow: 0 5px 5px rgb(54, 54, 54);
}

.Banner-List-Items {
  margin-top: 100px;
  /* border: 1px solid black; */
  /* margin-right: 6rem; */
  color: white;
}

.Banner-Hover {
  font-size: 48px;
  margin: 0;
}

.Banner-Hover:hover {
  font-size: 64px;
}

.Banner-Hover {
  cursor: default;
}

.Hover-Container {
  width: 45vw;
}

.List-Paras {
  height: 45vh;
}

.Nav-Link {
  text-decoration: none;
  cursor: pointer;
  color: unset;
}

@media only screen and (max-width: 800px) {
  .Banner-Hover {
    font-size: 32px;
    margin: 0;
  }
  
  .Banner-Hover:hover {
    font-size: 40px;
  }
  
  .List-Paras {
    margin-left: 2rem;
  }
}