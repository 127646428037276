.Project-Page-Container {
  flex: 1;
  display: flex;
  margin: 0;
  width: 100%;
  /* border: 1px solid green; */
  min-height: 100vh;
  /* margin: 40px; */
  overflow: hidden;
}

.Project-Info-Container {
  /* background-color: rgb(27, 100, 209); */
  flex: 1;
  color: white;
  padding: 40px;
  line-height: 24px;
  text-align: left;
  /* position: absolute; */
  /* overflow: hidden; */
}

.Project-Content-Container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  background-color: white;
}

.Project-Content-Inner-Padding {
  padding: 40px 60px 0 60px;
  min-height: min-content;
}

.Project-Info-Logo {
  /* width: 25%; */
  height: 100%;
  object-fit: cover;
}

.Project-Contant-Shrink {
  max-width: 50%;
  align-self: center;
}

.Project-Info-Logo-Container {
  text-align: center;
}

.Profile-Content-Header {
  color: rgb(27, 100, 209);
}

.Project-Content-Image {
  max-width: 100%;
  margin: 10px 0;
}

.Link-Button {
  margin-top: 20px;
}

.Link-Button > a {
  text-decoration: none;
  color: white;
}

.Return-Button {
  align-self: flex-end;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: end;
}

.Loader-Container {
  margin-top: 50px;
}

@media only screen and (max-width: 800px) {
  .Project-Content-Inner-Padding {
    padding: 40px 60px 0 60px;
  }
}