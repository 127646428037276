.Personal-Section {
  padding: 10px 80px;
  background-color: black;
  color: white;
  padding-top: 4rem;
}

.About-Section {
  flex: 1 0 33%; 
  padding-right: 40px;
}

.Contact-Form-Section {
  flex: 1 0 33%; 
  padding: 0 40px;
}

.About-Header {
  font-size: 2rem;
  margin: 0;
}

.Contact-Header {
  font-size: 2rem;
  margin-top: 0;
}

.Contact-Input {
  background-color: gray;
  margin: 10px 0;
  padding: 5px;
  border: 0;
  border-radius: 3px;
  color: white;
}

.Contact-Message {
  background-color: gray;
  margin: 10px 0;
  padding: 5px;
  border: 0;
  border-radius: 3px;
  height: 4rem;
  color: white;
}

.Contact-Input::placeholder, .Contact-Message::placeholder {
  color: darkgrey
}

.Send-Button {
  margin-top: 10px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  background-color: rgb(19, 109, 240);
  color: white;
  width: 100px;
  padding: 10px;
  font-size: 16px;
  align-self: flex-end;
}

.Contact-Form-Info {
  margin: 3px 0;
}

.Linkedin-Icon {
  height: 30px;
}

.Footer-Container {
  margin: 20px 0;
}

.Monster {
  background-image: url('./monster.png');
  background-repeat: no-repeat;
  background-position-x: 85%;
  background-position-y: 20%;
  background-size: 9rem;
  /* flex: 1 */
  flex: 1 0 33%; 
  padding: 0 40px;
}

.Phone-Number {
  font-size: 22px;
  margin: 0 0 10px 0;
}

@media only screen and (max-width: 800px) {
  .About-Section {
    align-items: center;
    padding-right: 0;
  }
  .Personal-Section {
    padding: 10px 40px;
  }
}

@media only screen and (max-width: 1400px) {
  .Monster {
    background-position-x: 72%;
    background-position-y: 23%;
    background-size: 7rem;
  }
}

@media only screen and (max-width: 1200px) {
  .Monster {
    background-image: none;
  }
}